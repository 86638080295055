import HttpClient from "@/services/axios/instance";

import { catchAxiosError } from "@/services/axios/error";

import { IResponse } from "@/services/axios/entities";
import {
  ICreateUpdateUserForm,
  IUserParameters,
  IUserVerificationDTO,
} from "@/models/User";
import { ICreateUpdateCustomerForm } from "@/models/Customer";

const USERS = "Users";
const env = JSON.parse(localStorage.getItem("env") as string);
export class UserAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public getList = async (params?: IUserParameters): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(USERS, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getUserInfo = async (params?: any): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${USERS}/${params}`)
      .catch(catchAxiosError);

    return response;
  };

  public getRoles = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${USERS}/roles`)
      .catch(catchAxiosError);

    return response;
  };

  public getSelection = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${USERS}/selection`)
      .catch(catchAxiosError);

    return response;
  };

  public updateUser = async (
    user: ICreateUpdateCustomerForm | ICreateUpdateUserForm
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${USERS}/${user.Id}`, user)
      .catch(catchAxiosError);

    return response;
  };

  public deleteUser = async (Id: number): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .delete(`${USERS}/${Id}`)
      .catch(catchAxiosError);

    return response;
  };

  public userVerification = async (
    data: IUserVerificationDTO
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${USERS}/verification`, data)
      .catch(catchAxiosError);

    return response;
  };
}
