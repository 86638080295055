import { ITenantUser, ITenantUserUpdate, IPasswordUser } from "@/models/Tenant";
import {
  ITenantUserParameters,
  ITenantUserPassword,
} from "@/models/TenantUser";
import { IResponse } from "@/services/axios/entities";
import { catchAxiosError } from "@/services/axios/error";
import HttpClient from "@/services/axios/instance";

const TENANT = "Tenants";
const env = JSON.parse(localStorage.getItem("env") as string);
export class TenantUserAPI extends HttpClient {
  constructor() {
    super(env?.VUE_APP_DOMAIN_API_SERVER);
  }

  public createUser = async (data: ITenantUser): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updateUser = async (
    UserId: string,
    data: ITenantUserUpdate
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .put(`${TENANT}/users/${UserId}`, data)
      .catch(catchAxiosError);

    return response;
  };

  public updatePassword = async (
    UserId: string,
    data: IPasswordUser
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users/${UserId}/passwords`, data)
      .catch(catchAxiosError);

    return response;
  };

  public getListTenantUser = async (
    params: ITenantUserParameters
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/users`, { params })
      .catch(catchAxiosError);

    return response;
  };

  public getTenantUser = async (
    UserId: string | number
  ): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/users/${UserId}`)
      .catch(catchAxiosError);

    return response;
  };

  public sendActivationEmail = async (UserId: string): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .post(`${TENANT}/users/${UserId}/send-activation-email`)
      .catch(catchAxiosError);
    return response;
  };

  public getTenantProfile = async (): Promise<IResponse> => {
    const response: IResponse = await this.instance
      .get(`${TENANT}/profile`)
      .catch(catchAxiosError);
    return response;
  };
}
